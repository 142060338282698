import {gql} from "@apollo/client";

import * as fragments from "./fragments";

export const COUNT_SUBSCRIPTION = gql`
  subscription Count($target: Int) {
    count(target: $target)
  }
`;

export const CHAT_MESSAGES = gql`
  ${fragments.CHAT_MESSAGE}

  subscription ChatMessages {
    chatMessages {
      ...ChatMessageFields
    }
  }
`;

export const UPDATES = gql`
  ${fragments.SEARCHABLE_FIELDS}

  subscription Updates($inputs: [UpdateInput!]!) {
    updates(inputs: $inputs) {
      updatedAt
      modelClass
      modelId
      model {
        ...SearchableFields
      }
    }
  }
`;
