import {useMutation, useQuery} from "@apollo/client";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import {
  Button,
  Center,
  Checkbox,
  MantineSize,
  SimpleGrid,
  Space,
  Text,
  Textarea,
  TextInput,
  Tooltip,
} from "@mantine/core";
import {Formik, FormikErrors} from "formik";
import {isEmpty} from "lodash";
import Link from "next/link";
import {useRouter} from "next/router";
import {useState} from "react";

import config from "../../config";
import {SIGNUP_REASON_REQUIRED} from "../../constants";
import * as mutations from "../../graphql/mutations";
import type {SignUpInput} from "../../graphql/types";
import {useLocalStorage} from "../../utils";
import ErrorAlert from "../ErrorAlert";
import Loader from "../Loader";
import classes from "./SignUpForm.module.scss";
import * as queries from "../../graphql/queries";

export interface SignUpFormProps {
  from?: string | null; // URL path the user came from
  error?: string | null; // Initial error message to display
  message?: string | null; // Sign up message (e.g. "sign up to do this action")
  buttonSize?: MantineSize; // Size of the "Sign up" button
  invitationToken?: string | null;
  onSignInClick?(): void; // Callback to invoke when the "Sign in" link is clicked (default: navigates to the /signIn page)
  onComplete?(from?: string): void; // Callback to invoke when the sign up process completes (put your authenticated actions here)
  onNavigateAway?(): void; // Callback to invoke when the user navigates to a different page w/ a link (e.g. close the modal)
}

/**
 * Sign up form
 *
 * Promps user for their registration details and creates their inital
 * (deactivated) account
 */
export default function SignUpForm(props: SignUpFormProps) {
  const [lastError, setLastError] = useState<string | null>(
    props.error || null
  );
  const [referral, _setReferral] = useLocalStorage<string | null>(
    "referral",
    null
  );
  const router = useRouter();
  const [signUp, _signUpResult] = useMutation(mutations.SIGNUP);

  // Default values for form
  const {
    loading: invitationDefaultsLoading,
    data: {users: {invitationDefaults = undefined} = {}} = {},
  } = useQuery(queries.GET_INVITATION_DEFAULTS, {
    variables: {token: props.invitationToken},
    skip: !props.invitationToken,
  });

  if (invitationDefaultsLoading) {
    // Need to wait for defaults to load before initializing form
    return <Loader size="xl" />;
  }

  const message = props.message || (
    <>
      Unlock more features by signing up for an account. It&apos;s free and
      easy!
    </>
  );

  return (
    <Formik<SignUpInput>
      initialValues={{
        email: invitationDefaults?.email || "",
        firstName: "",
        lastName: "",
        captchaResponse: "",
        reason: "",
        referral,
        invitationToken: props.invitationToken,
        acceptedTerms: false,
      }}
      validate={async values => {
        const errors: FormikErrors<SignUpInput> = {};

        if (!values.email) {
          errors.email = "Email is required";
        }

        if (!values.firstName) {
          errors.firstName = "First name is required";
        }

        if (!values.lastName) {
          errors.lastName = "Last name is required";
        }

        if (SIGNUP_REASON_REQUIRED && (values.reason || "").length < 5) {
          errors.reason =
            "Please give a more detailed reason you would like to participate in the beta";
        }

        if (!values.acceptedTerms) {
          errors.acceptedTerms =
            "You must accept the terms of service if you want to sign up";
        }

        return errors;
      }}
      onSubmit={async (input: SignUpInput, {setSubmitting, setErrors}) => {
        setLastError(null);

        try {
          const {data: {users: {signUp: signUpResult = {}} = {}} = {}} =
            await signUp({
              variables: {...input},
            });

          // Form error response?
          if (signUpResult.__typename == "FormError") {
            if (
              !signUpResult.field ||
              signUpResult.field === "captchaResponse"
            ) {
              // General error
              setLastError(signUpResult.message);
            } else {
              // Field-specific error
              setErrors({[signUpResult.field]: signUpResult.message});
            }

            return;
          }

          await router.push("/signUp/pending");

          // TODO: Call onComplete() after the user activates their account
          if (props?.onComplete) {
            props.onComplete(props.from || undefined);
          }
        } catch (e: any) {
          setLastError(e.message || "Sign up failed");
        } finally {
          setSubmitting(false);
        }
      }}>
      {({
        handleSubmit,
        handleChange,
        values,
        errors,
        isValidating,
        isSubmitting,
        submitCount,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          {message && <div className={classes.message}>{message}</div>}

          <Text size="sm">
            Already have an account?{" "}
            <strong>
              <Link
                href={props.from ? `/signIn?from=${props.from}` : "/signIn"}
                passHref
                legacyBehavior>
                <a
                  onClick={event => {
                    if (props.onSignInClick) {
                      event.preventDefault();
                      props.onSignInClick();
                    } else if (props.onNavigateAway) {
                      props.onNavigateAway();
                    }
                  }}>
                  Sign in
                </a>
              </Link>{" "}
            </strong>
            instead.
          </Text>

          <br />

          <ErrorAlert message={lastError} />

          <SimpleGrid cols={2}>
            <TextInput
              name="firstName"
              label="First name"
              required
              error={submitCount > 0 && errors.firstName}
              value={values.firstName}
              onChange={handleChange}
              autoCapitalize="none"
            />

            <TextInput
              name="lastName"
              label="Last name"
              required
              error={submitCount > 0 && errors.lastName}
              value={values.lastName}
              onChange={handleChange}
              autoCapitalize="none"
            />
          </SimpleGrid>

          <br />

          <TextInput
            name="email"
            label="Email address"
            required
            error={submitCount > 0 && errors.email}
            value={values.email}
            onChange={handleChange}
            autoCapitalize="none"
          />

          {SIGNUP_REASON_REQUIRED && (
            <>
              <Space h="lg" />
              <Textarea
                name="reason"
                label="Beta participation reason"
                placeholder="We're beta testing, so not everything may be ready. Please explain why wou'd like to participate in this beta test..."
                required
                error={submitCount > 0 && errors.reason}
                value={values.reason || ""}
                onChange={handleChange}
              />
            </>
          )}

          <Space h="xl" />

          <Center>
            <Checkbox
              name="acceptedTerms"
              label={
                <span>
                  I have read and accept the{" "}
                  <Link href="/legal/terms" legacyBehavior passHref>
                    <a target="_blank">terms of service</a>
                  </Link>
                </span>
              }
              error={submitCount > 0 && errors.acceptedTerms}
              checked={values.acceptedTerms}
              onChange={event =>
                setFieldValue("acceptedTerms", event.target.checked)
              }
            />
          </Center>

          <br />

          <Center>
            <HCaptcha
              sitekey={config.hcaptcha.siteKey}
              onVerify={(token, _ekey) => {
                setFieldValue("captchaResponse", token);
              }}
            />
          </Center>

          <br />

          <Center>
            <Tooltip
              withArrow
              label={isEmpty(errors) ? "Sign up" : "Please correct errors"}
              color={isEmpty(errors) ? undefined : "red"}>
              <Button
                type="submit"
                size={props.buttonSize || "lg"}
                variant="primary"
                onClick={() => handleSubmit()}
                disabled={isValidating || isSubmitting}
                loading={isSubmitting}
                leftSection={
                  !isSubmitting &&
                  !isEmpty(errors) &&
                  submitCount > 0 && (
                    <FontAwesomeIcon icon={faExclamationTriangle} beatFade />
                  )
                }>
                Sign Up
              </Button>
            </Tooltip>
          </Center>
        </form>
      )}
    </Formik>
  );
}
