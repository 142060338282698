import type {IconDefinition} from "@fortawesome/fontawesome-common-types";
import {
  faEye,
  faChevronDown,
  faMap,
  faUser,
  faUserPlus,
  faPowerOff,
  faGear,
  faComment,
  faUpload,
  faCalendarDays,
  faCircleQuestion,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Burger, Center, Drawer, Group, Menu, Stack} from "@mantine/core";
import {useDisclosure} from "@mantine/hooks";
import classNames from "classnames";
import {compact} from "lodash";
import Link from "next/link";
import {useRouter} from "next/router";
import {MouseEvent, ReactNode} from "react";

import Avatar from "../Avatar";
import classes from "./Header.module.scss";
import {Permissions} from "../../graphql/types";
import {useAuth} from "../contexts/AuthContext";
import {show as showChat} from "../modals/Chat/Chat";
import {showSignIn, showSignUp} from "../modals/CombinedSignIn/CombinedSignIn";
import {show as showInvite} from "../modals/Invite";
import {show as showSettings} from "../modals/Settings/Settings";

export type MenuLink = {
  label: string | ReactNode;
  link: string;
  prefetch?: boolean; // https://nextjs.org/docs/app/api-reference/components/link#prefetch
  visible?: boolean;
  icon?: IconDefinition;
  onClick?: (event: MouseEvent<HTMLAnchorElement>) => boolean;
  links?: MenuItem[]; // Child links
};

export type MenuDivider = null;

export type MenuItem = MenuLink | MenuDivider;

/**
 * Header nav bar for whole site
 */
export default function Header() {
  const router = useRouter();
  const {authentication, hasPermission} = useAuth();
  const [opened, {toggle, close}] = useDisclosure(false);

  const linkMatchesPath = (link: string): boolean => {
    return router.asPath.startsWith(link);
  };

  const links: MenuItem[] = [
    {
      label: "Map",
      icon: faMap,
      link: "/search",
    },
    {
      label: "Watches",
      icon: faEye,
      link: "/watches",
    },
    {
      label: "Help",
      icon: faCircleQuestion,
      link: "/help",
    },
  ];

  if (authentication.status === "SIGNED_IN" && authentication.user) {
    links.push({
      label: (
        <Group gap="xs">
          <Avatar size="sm" user={authentication.user} />
          {" " + authentication.user?.firstName}
        </Group>
      ),
      link: "#",
      onClick: event => {
        event.preventDefault();
        return false;
      },
      links: [
        {
          label: "Settings",
          icon: faGear,
          link: "#",
          onClick: event => {
            event.preventDefault();
            showSettings();
            return true;
          },
        },
        {
          label: "Chat",
          icon: faComment,
          link: "#",
          onClick: event => {
            event.preventDefault();
            showChat();
            return true;
          },
        },
        {
          label: "Upload",
          icon: faUpload,
          link: "/upload",
          visible: hasPermission(Permissions.Admin),
        },
        {
          label: "Scan History",
          icon: faCalendarDays,
          link: "/facilities",
          visible: hasPermission(Permissions.Admin),
        },
        {
          label: "Invite",
          icon: faPlus,
          link: "#",
          onClick: event => {
            event.preventDefault();
            showInvite();
            return true;
          },
        },
        null,
        {
          label: "Sign Out",
          link: "/signOut",
          prefetch: false,
          icon: faPowerOff,
        },
      ],
    });
  } else {
    links.push({
      label: "Sign In",
      link: "/signIn",
      onClick: event => {
        event.preventDefault();
        showSignIn();
        return true;
      },
      icon: faUser,
    });
    links.push({
      label: "Sign Up",
      link: "/signUp",
      onClick: event => {
        event.preventDefault();
        showSignUp();
        return true;
      },
      icon: faUserPlus,
    });
  }

  const items = links
    .filter(link => link === null || link.visible || link.visible === undefined)
    .map((link, i) => {
      if (link === null) {
        return null;
      }

      const childItems = link.links
        ?.filter(
          link => link === null || link.visible || link.visible === undefined
        )
        .map((childLink, j) => {
          if (childLink === null) {
            return <Menu.Divider key={j} />;
          }

          return (
            <Menu.Item
              key={j}
              leftSection={
                childLink.icon && <FontAwesomeIcon icon={childLink.icon} />
              }>
              <Link
                href={childLink.link}
                prefetch={childLink.prefetch}
                className={classes.link}
                onClick={event => {
                  if (childLink.onClick) {
                    if (!childLink.onClick(event)) {
                      return;
                    }
                  }

                  close();
                }}>
                {childLink.label}
              </Link>
            </Menu.Item>
          );
        });

      if (childItems) {
        return (
          <Menu
            key={i}
            trigger="hover"
            transitionProps={{exitDuration: 0}}
            classNames={{
              item: classNames(classes.menuItem, {
                [classes.menuItemActive]: linkMatchesPath(link.link),
              }),
            }}
            withinPortal>
            <Menu.Target>
              <Link
                href={link.link}
                prefetch={link.prefetch}
                className={classes.link}
                onClick={event => {
                  if (link.onClick) {
                    if (!link.onClick(event)) {
                      return;
                    }
                  }

                  close();
                }}>
                <Center>
                  <span className={classes.linkLabel}>{link.label}</span>
                  <FontAwesomeIcon icon={faChevronDown} />
                </Center>
              </Link>
            </Menu.Target>
            <Menu.Dropdown>{childItems}</Menu.Dropdown>
          </Menu>
        );
      }

      return (
        <Link
          key={i}
          href={link.link}
          className={classNames(classes.link, classes.menuItem, {
            [classes.menuItemActive]: linkMatchesPath(link.link),
          })}
          onClick={event => {
            if (link.onClick) {
              if (!link.onClick(event)) {
                return;
              }
            }

            close();
          }}>
          {link.icon && <FontAwesomeIcon icon={link.icon} className="mr-2" />}
          {link.label}
        </Link>
      );
    });

  return (
    <header className={classes.header}>
      <div className={classes.inner}>
        <div className={classes.brand}>
          <Link href="/">
            <Group gap="sm">
              <img
                className="logo d-inline-block align-middle"
                src="/images/logo.png"
                alt="Camp Observer"
                width={28}
                height={28}
              />{" "}
              Camp Observer
            </Group>
          </Link>
        </div>
        <Group gap={5} visibleFrom="sm">
          {compact(items)}
        </Group>
        <Burger opened={opened} onClick={toggle} size="sm" hiddenFrom="sm" />
        <Drawer opened={opened} onClose={toggle} position="top">
          <h2>Menu</h2>
          <Stack align="flex-start">{compact(items)}</Stack>
        </Drawer>
      </div>
    </header>
  );
}
