import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ContextModalProps, modals} from "@mantine/modals";
import React from "react";

export interface WaitProps {
  message?: string; // Waiting message to display
}

/**
 * Modal dialog to display when waiting for something
 */
export default function Wait({innerProps}: ContextModalProps<WaitProps>) {
  return (
    <>
      <FontAwesomeIcon icon={faSpinner} spin />
      <div>{innerProps.message || "Please wait..."}</div>
    </>
  );
}

/**
 * Show the wait dialog
 */
export function show(message: string) {
  modals.openContextModal({
    modal: "wait",
    centered: true,
    closeOnClickOutside: false,
    closeOnEscape: false,
    innerProps: {message},
  });
}
