import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  Button,
  Center,
  MantineSize,
  PasswordInput,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core";
import {Formik, FormikErrors} from "formik";
import {isEmpty} from "lodash";
import Link from "next/link";
import React, {useState} from "react";

import {useAuth} from "../contexts/AuthContext";
import ErrorAlert from "../ErrorAlert";
import classes from "./SignInForm.module.scss";

export interface Credentials {
  email: string;
  password: string;
}

export interface SignInFormProps {
  from?: string | null; // URL path the user came from
  email?: string | null; // Initial email address to populate
  error?: string | null; // Initial error message to display
  message?: string | null; // Sign in message (e.g. "sign in to do this action")
  buttonSize?: MantineSize; // Size of the "Sign in" button
  onSignUpClick?(): void; // Callback to invoke when the "Sign up" link is clicked (default: navigates to the /signUp page)
  onComplete?(from?: string): void; // Callback to invoke when the sign in process completes (put your authenticated actions here)
  onNavigateAway?(): void; // Callback to invoke when the user navigates to a different page w/ a link (e.g. close the modal)
}

/**
 * Sign in form
 *
 * Prompts user for their credentials and logs them in
 */
export default function SignInForm(props: SignInFormProps) {
  const [lastError, setLastError] = useState<string | null>(
    props.error || null
  );
  const {login} = useAuth();

  return (
    <Formik<Credentials>
      initialValues={{email: props.email || "", password: ""}}
      validate={async values => {
        const errors: FormikErrors<Credentials> = {};

        if (!values.email) {
          errors.email = "Email is required";
        }

        if (!values.password) {
          errors.password = "Password is required";
        }

        return errors;
      }}
      onSubmit={async ({email, password}: Credentials, {setSubmitting}) => {
        setLastError(null);

        try {
          await login(email, password);
        } catch (e: any) {
          setLastError(e.message || "Sign in failed");
          return;
        } finally {
          setSubmitting(false);
        }

        if (props.onComplete) {
          props.onComplete(props.from || undefined);
        }
      }}>
      {({
        handleSubmit,
        handleChange,
        values,
        errors,
        isValidating,
        isSubmitting,
        submitCount,
      }) => (
        <form onSubmit={handleSubmit}>
          {props.message && (
            <Text className={classes.message}>{props.message}</Text>
          )}

          <Text size="sm">
            Don&apos;t have an account yet?{" "}
            <strong>
              <Link
                href={props.from ? `/signUp?from=${props.from}` : "/signUp"}
                passHref
                legacyBehavior>
                <a
                  onClick={event => {
                    if (props.onSignUpClick) {
                      event.preventDefault();
                      props.onSignUpClick();
                    } else if (props.onNavigateAway) {
                      props.onNavigateAway();
                    }
                  }}>
                  Sign up!
                </a>
              </Link>
            </strong>
          </Text>

          <br />

          <ErrorAlert message={lastError} />

          <TextInput
            name="email"
            label="Email address"
            placeholder="Enter email address"
            required
            error={submitCount > 0 && errors.email}
            value={values.email}
            onChange={handleChange}
            autoCapitalize="none"
          />

          <br />

          <PasswordInput
            name="password"
            label="Password"
            placeholder="Password"
            required
            error={submitCount > 0 && errors.password}
            value={values.password}
            onChange={handleChange}
          />

          <div className={classes.forgotPassword}>
            <Link
              href={
                values.email
                  ? `/resetPassword?email=${encodeURIComponent(values.email)}`
                  : "/resetPassword"
              }
              tabIndex={-1}
              passHref
              legacyBehavior>
              <a title="Reset your forgotten password" target="_blank">
                Forget your password?
              </a>
            </Link>
          </div>

          <br />

          <Center>
            <Tooltip
              withArrow
              label={isEmpty(errors) ? "Sign in" : "Please correct errors"}
              color={isEmpty(errors) ? undefined : "red"}>
              <Button
                type="submit"
                size={props.buttonSize || "lg"}
                variant="primary"
                onClick={() => handleSubmit()}
                disabled={isValidating || isSubmitting}
                loading={isSubmitting}
                leftSection={
                  !isSubmitting &&
                  !isEmpty(errors) &&
                  submitCount > 0 && (
                    <FontAwesomeIcon icon={faExclamationTriangle} beatFade />
                  )
                }>
                Sign In
              </Button>
            </Tooltip>
          </Center>
        </form>
      )}
    </Formik>
  );
}
