import {gql} from "@apollo/client";

export const PAGINATION_FIELDS = gql`
  fragment PaginationFields on PaginationResults {
    page
    pageSize
    totalResults
  }
`;

export const ADDRESS_FIELDS = gql`
  fragment AddressFields on Address {
    id
    type
    street
    city
    state
    zip
    country
    version
    isActive
    updatedAt
  }
`;

export const CLOSURE_FIELDS = gql`
  fragment ClosureFields on Closure {
    id
    beginsAt
    endsAt
    reason
    version
    isActive
    updatedAt
  }
`;

export const EQUIPMENT_FIELDS = gql`
  fragment EquipmentFields on Equipment {
    id
    name
    description
    isActive
    updatedAt
  }
`;

export const MEDIA_FIELDS = gql`
  fragment MediaFields on Media {
    id
    type
    title
    subtitle
    description
    credits
    url
    width
    height
    version
    isActive
    updatedAt
  }
`;

export const MEDIA_MARKER_FIELDS = gql`
  fragment MediaMarkerFields on Media {
    id
    title
    url
    width
    height
  }
`;

export const FACILITY_MARKER_FIELDS = gql`
  ${MEDIA_MARKER_FIELDS}

  fragment FacilityMarkerFields on Facility {
    location {
      latitude
      longitude
    }
    id
    slug
    name
    primaryMedia {
      ...MediaMarkerFields
    }
    checkRequested
    nextCheckRequestAvailableAt
    lastCheckedAt
  }
`;

export const UNIT_MARKER_FIELDS = gql`
  ${MEDIA_MARKER_FIELDS}
  ${FACILITY_MARKER_FIELDS}

  fragment UnitMarkerFields on Unit {
    location {
      latitude
      longitude
    }
    id
    slug
    name
    primaryMedia {
      ...MediaMarkerFields
    }
    facility {
      ...FacilityMarkerFields
    }
  }
`;

export const UNIT_FIELDS = gql`
  ${EQUIPMENT_FIELDS}
  ${CLOSURE_FIELDS}
  ${MEDIA_FIELDS}

  fragment UnitFields on Unit {
    id
    slug
    type
    useType
    name
    loopName
    location {
      latitude
      longitude
    }
    bookingUrl
    peopleCapacity {
      min
      max
    }
    rules
    isGroup
    isReservable
    isAdaAccessible
    petsAllowed
    firePit
    picnicTable
    waterHookup
    sewerHookup
    electricityHookupAmps
    drivewayLengthFeet
    maxVehicles
    maxVehicleLengthFeet
    favoritesCount
    isEquipmentMandatory
    facility {
      id
      slug
      name
      park {
        id
        slug
        name
      }
    }
    allowedEquipment {
      ...EquipmentFields
    }
    closures {
      ...ClosureFields
    }
    media {
      ...MediaFields
    }
    primaryMedia {
      ...MediaFields
    }
    version
    isActive
    updatedAt
  }
`;

export const FACILITY_UNIT_FIELDS = gql`
  ${MEDIA_FIELDS}

  fragment FacilityUnitFields on Unit {
    id
    slug
    type
    useType
    name
    loopName
    location {
      latitude
      longitude
    }
    bookingUrl
    peopleCapacity {
      min
      max
    }
    rules
    isGroup
    isReservable
    isAdaAccessible
    petsAllowed
    maxVehicles
    maxVehicleLengthFeet
    isEquipmentMandatory
    primaryMedia {
      ...MediaFields
    }
  }
`;

export const FACILITY_COMMON_FIELDS = gql`
  ${ADDRESS_FIELDS}
  ${CLOSURE_FIELDS}
  ${MEDIA_FIELDS}

  fragment FacilityCommonFields on Facility {
    id
    slug
    type
    name
    url
    bookingUrl
    reservationUrl
    mapUrl
    checkinHour
    checkoutHour
    description
    descriptionHtml
    feeDescription
    rules
    directions
    stayLimit
    phone
    email
    isReservable
    isAdaAccessible
    location {
      latitude
      longitude
    }
    favoritesCount
    unitCount
    addresses {
      ...AddressFields
    }
    closures {
      ...ClosureFields
    }
    media {
      ...MediaFields
    }
    primaryMedia {
      ...MediaFields
    }
    isWatched
    isShared
    version
    isActive
    updatedAt
    checkRequested
    nextCheckRequestAvailableAt
    lastCheckedAt
  }
`;

export const FACILITY_FIELDS = gql`
  ${FACILITY_UNIT_FIELDS}
  ${FACILITY_COMMON_FIELDS}

  fragment FacilityFields on Facility {
    ...FacilityCommonFields
    units {
      ...FacilityUnitFields
    }
  }
`;

export const FACILITY_WITHOUT_UNITS_FIELDS = gql`
  ${FACILITY_COMMON_FIELDS}

  fragment FacilityWithoutUnitsFields on Facility {
    ...FacilityCommonFields
    unitCount
  }
`;

export const ORGANIZATION_FIELDS = gql`
  ${ADDRESS_FIELDS}

  fragment OrganizationFields on Organization {
    id
    name
    url
    addresses {
      ...AddressFields
    }
    version
    isActive
    updatedAt
  }
`;

export const PARK_MARKER_FIELDS = gql`
  ${MEDIA_MARKER_FIELDS}

  fragment ParkMarkerFields on Park {
    location {
      latitude
      longitude
    }
    id
    slug
    name
    primaryMedia {
      ...MediaMarkerFields
    }
  }
`;

export const PARK_FIELDS = gql`
  ${ADDRESS_FIELDS}
  ${CLOSURE_FIELDS}
  ${MEDIA_FIELDS}
  ${FACILITY_WITHOUT_UNITS_FIELDS}

  fragment ParkFields on Park {
    id
    slug
    name
    description
    descriptionHtml
    feeDescription
    directions
    stayLimit
    phone
    email
    reservationUrl
    mapUrl
    isReservable
    location {
      latitude
      longitude
    }
    geometryUrl
    organizationId
    favoritesCount
    addresses {
      ...AddressFields
    }
    closures {
      ...ClosureFields
    }
    media {
      ...MediaFields
    }
    primaryMedia {
      ...MediaFields
    }
    facilities {
      ...FacilityWithoutUnitsFields
    }
    version
    isActive
    updatedAt
  }
`;

export const SLOT_FIELDS = gql`
  fragment SlotFields on Slot {
    id
    slotDate
    status
    version
    updatedAt
    rate {
      perNight
      perPerson
      fees
      displayRate
      displayFees
    }
  }
`;

// Basic fields that everyone sees
export const USER_FIELDS = gql`
  fragment UserFields on User {
    id
    displayName
    avatarImageUrl
  }
`;

// Fields only accessible to own authenticated user
export const AUTHENTICATED_USER_FIELDS = gql`
  ${USER_FIELDS}

  fragment AuthenticatedUserFields on User {
    ...UserFields
    firstName
    lastName
    email
    settingsJson
    maxWatchCredits
    usedWatchCredits
    permissions
    version
    isActive
    updatedAt
  }
`;

// Fields only accessible to administrators
export const ADMIN_USER_FIELDS = gql`
  ${AUTHENTICATED_USER_FIELDS}

  fragment AdminUserFields on User {
    roles
  }
`;

export const CHAT_MESSAGE = gql`
  ${USER_FIELDS}

  fragment ChatMessageFields on ChatMessage {
    id
    sender {
      ...UserFields
    }
    recipient {
      ...UserFields
    }
    message
    sentAt
    deletedAt
  }
`;

export const FILTER = gql`
  fragment FilterFields on Filter {
    dates {
      min
      max
    }
    nights
    people {
      min
      max
    }
    excludeGroup
    onlyPetsAllowed
    onlyAccessible
    excludeMandatoryEquipment
    maxVehicleLengthFeet
    vehicles
  }
`;

export const WATCH = gql`
  ${FILTER}

  fragment WatchFields on Watch {
    id
    name
    displayName
    creator {
      id
    }
    filter {
      ...FilterFields
    }
    scheduleDescription
    status
    expiresAt
    lastScannedAt
    alertsEnabled
    version
    createdAt
    updatedAt
    parks {
      id
      slug
      name
    }
    facilities {
      id
      name
      park {
        id
        slug
        name
      }
    }
    units {
      id
      slug
      name
      facility {
        id
        slug
        name
        park {
          id
          slug
          name
        }
      }
    }
    effectiveFacilities {
      id
      slug
      name
      park {
        id
        slug
        name
      }
    }
    foundFacilities {
      id
      slug
      name
      park {
        id
        slug
        name
      }
    }
  }
`;

export const SEARCHABLE_FIELDS = gql`
  ${PARK_FIELDS}
  ${FACILITY_FIELDS}
  ${UNIT_FIELDS}

  fragment SearchableFields on SearchResultType {
    ... on Park {
      ...ParkFields
    }
    ... on Facility {
      ...FacilityFields
    }
    ... on Unit {
      ...UnitFields
    }
  }
`;

export const CONTENT_FIELDS = gql`
  ${USER_FIELDS}

  fragment ContentFields on Content {
    type
    id
    slug
    status
    title
    tagline
    bodyMarkdown
    isFeatured
    sortOrder
    creator {
      ...UserFields
    }
    showCreator
    createdAt
    updatedAt
    version
  }
`;

export const CONTENT_ATTACHMENT_FIELDS = gql`
  fragment ContentAttachmentFields on ContentAttachment {
    id
    contentId
    title
    fileName
    mimeType
    dataUrl
    createdAt
    updatedAt
  }
`;
