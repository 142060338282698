import {track} from "@amplitude/analytics-browser";
import {useQuery} from "@apollo/client";
import {faCircleQuestion} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Link from "next/link";
import React, {useEffect, useMemo} from "react";

import ErrorAlert from "../ErrorAlert";
import classes from "./HelpLink.module.scss";
import * as queries from "../../graphql/queries";
import Loader from "../Loader";

export interface HelpLinkProps {
  slug?: string | null; // Help page slug
  title?: string | null; // Title for the link, otherwise it's automatically determined by the help page's title
  children?: React.ReactNode;
}

/**
 * Link to help page
 */
export default function HelpLink({slug, title, children}: HelpLinkProps) {
  const {
    loading,
    error,
    data: {cms: {content = undefined} = {}} = {},
  } = useQuery(queries.GET_CONTENT_SUMMARY, {
    variables: {
      slug,
      type: "help",
    },
    skip: !!title,
  });

  useEffect(() => {
    track("Help Link Clicked", {slug});
  }, []);

  const helpUrl = useMemo(() => {
    if (slug) {
      return `/help/${encodeURIComponent(slug)}`;
    }

    return "/help";
  }, [slug]);

  if (loading) {
    return <Loader size="xs" />;
  }

  const givenTitle = children || title || content?.title;
  const helpIcon = <FontAwesomeIcon icon={faCircleQuestion} />;

  return (
    <>
      <ErrorAlert message={error?.message} />
      <Link href={helpUrl} passHref legacyBehavior>
        <a target="_blank" className={classes.helpLink}>
          {givenTitle ? (
            <>
              {givenTitle || slug}
              <sup>{helpIcon}</sup>
            </>
          ) : (
            helpIcon
          )}
        </a>
      </Link>
    </>
  );
}
