import React, {useEffect, useState} from "react";

import Typer from "../components/Typer";

export interface TextCyclerProps {
  textList: string[]; // Messages to cycle through
  delaySeconds?: number; // Cycle interval delay (in seconds)
}

/**
 * Cycles through a list of messages on a fixed interval
 */
export default function TextCycler({
  textList,
  delaySeconds = 6.0,
}: TextCyclerProps) {
  const randText = (): string =>
    textList[Math.floor(Math.random() * textList.length)];

  const [text, setText] = useState<string>(randText());

  const nextText = (): string => {
    let nextText: string | null = null;

    while (!nextText || nextText == text) {
      nextText = randText();
    }

    return nextText;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setText(nextText());
    }, delaySeconds * 1000);

    return () => clearInterval(interval);
  }, []);

  return <Typer text={text} />;
}
