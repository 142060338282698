import {useSubscription, useMutation} from "@apollo/client";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Avatar, Group, Paper, TextInput} from "@mantine/core";
import {ContextModalProps, modals} from "@mantine/modals";
import {notifications} from "@mantine/notifications";
import classNames from "classnames";
import React, {useEffect, useRef, useState} from "react";

import classes from "./Chat.module.scss";
import * as mutations from "../../../graphql/mutations";
import * as subscriptions from "../../../graphql/subscriptions";
import type {ChatMessage} from "../../../graphql/types";
import {getLogger} from "../../../logging";
import {useAuth} from "../../contexts/AuthContext";

const log = getLogger(__filename);

/**
 * Chat dialog
 *
 * Subscribs to real-time chat messages
 */
export default function Chat({}: ContextModalProps) {
  const {authentication} = useAuth();
  const [errors, setErrors] = useState<string[]>([]);
  const [messageDraft, setMessageDraft] = useState<string>("");
  const [chatMessages, setChatMessages] = useState<ChatMessage[]>([]);
  const [sendingMessage, setSendingMessage] = useState<boolean>(false);
  const {error: messageError, data: {chatMessages: message = undefined} = {}} =
    useSubscription(subscriptions.CHAT_MESSAGES);
  const [sendChatMessage, _sendChatMessageResult] = useMutation(
    mutations.SEND_CHAT_MESSAGE
  );
  const chatLog = useRef<HTMLDivElement>(null);
  const outgoingMessageInput = useRef<HTMLInputElement>(null);

  const appendError = (error: string) => {
    setErrors([...errors, error]);
  };

  const appendIncomingMessage = (message: ChatMessage) => {
    setChatMessages([...chatMessages, message]);

    if (authentication.user?.id !== message.sender?.id) {
      notifications.show({
        title: message.sender ? `${message.sender.displayName}` : "Message",
        color: "blue",
        // icon: <FontAwesomeIcon icon={faComment} />,
        message: (
          <Group>
            {message.sender && (
              <Avatar
                src={message.sender.avatarImageUrl}
                alt={message.sender.displayName}
                size="md"
              />
            )}
            <Paper
              shadow="xs"
              p="xs"
              radius="lg"
              withBorder
              className={classes.bubble}>
              <a
                href="#"
                onClick={event => {
                  // Open chat modal when message is clicked
                  event.preventDefault();
                  show();
                }}>
                {message.message}
              </a>
            </Paper>
          </Group>
        ),
      });
    }
  };

  const sendMessage = async () => {
    if (!messageDraft) {
      return;
    }

    log.debug("Chat: Sending message", messageDraft);
    setSendingMessage(true);

    await sendChatMessage({
      variables: {
        message: messageDraft,
      },
    });

    setSendingMessage(false);
    setMessageDraft("");
    outgoingMessageInput.current?.focus();
  };

  useEffect(() => {
    if (message) {
      log.debug("Chat: Received message", message);
      appendIncomingMessage(message);
    }
  }, [message]);

  useEffect(() => {
    if (messageError) {
      appendError(`Error receiving message: ${messageError.message}`);
    }
  }, [messageError]);

  const scrollToBottom = () => {
    if (!chatLog.current) {
      return;
    }

    chatLog.current.scrollTop = chatLog.current.scrollHeight;
  };

  useEffect(() => scrollToBottom(), [chatMessages]);

  return (
    <div className={classes.chatModal}>
      <div className={classes.messages} ref={chatLog}>
        {chatMessages.map(chatMessage => (
          <div key={chatMessage.id} className={classes.message}>
            <span className={classes.messageSender}>
              {chatMessage.sender?.displayName || "(Unknown Sender)"}:
            </span>
            <span className="chat-message-body">{chatMessage.message}</span>
          </div>
        ))}
        {errors.map((error, i) => (
          <div key={i} className={classes.messageError}>
            {error}
          </div>
        ))}
      </div>
      <div className={classes.input}>
        {sendingMessage && (
          <div className={classes.loader}>
            <FontAwesomeIcon
              icon={faSpinner}
              spin
              className={classes.loaderIcon}
            />
          </div>
        )}
        <TextInput
          className={classNames(classes.inputMessage, {
            sending: sendingMessage,
          })}
          data-autofocus
          ref={outgoingMessageInput}
          value={messageDraft}
          readOnly={sendingMessage}
          onChange={event => setMessageDraft(event.target.value)}
          onKeyDown={async event => {
            if (event.key === "Enter") {
              await sendMessage();
            }
          }}
        />
      </div>
    </div>
  );
}

/**
 * Show chat dialog
 */
export function show() {
  const modalId = "chat";
  modals.close(modalId); // Ensure duplicate isn't opened
  modals.openContextModal({
    modal: modalId,
    title: "Chat (experimental)",
    centered: true,
    withOverlay: false,
    keepMounted: true,
    closeOnClickOutside: false,
    closeOnEscape: false,
    size: "xl",
    innerProps: {},
  });
}
