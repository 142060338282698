// @ts-nocheck
//
// Type checking is disabled because of the hoops I have to jump through to get
// a portal to render a null child. I'm spending too much time trying to satisfy
// the type checker at this point.
import {ReactNode, ReactPortal, useEffect, useState} from "react";
import {createPortal} from "react-dom";

export interface PortalProps {
  targetId?: string;
  children?: ReactNode;
}

/**
 * React portal
 */
export default function Portal({
  targetId = "portal",
  children,
}: PortalProps): ReactPortal {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);

    return () => setMounted(false);
  }, []);

  if (!mounted) {
    return null;
  }

  const document: Document | undefined = window?.document;
  if (!document) {
    return null;
  }

  const targetElement: Element | null = document.querySelector(`#${targetId}`);
  if (!targetElement) {
    return null;
  }

  return createPortal(children, targetElement);
}
