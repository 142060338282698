import {Container, Overlay, Text, Title} from "@mantine/core";
import React from "react";

import classes from "./Hero.module.scss";

interface HeroProps {
  title?: React.ReactNode;
  description?: React.ReactNode;
  backgroundImageUrl: string;
  children?: React.ReactNode;
}

export default function Hero({
  title,
  description,
  backgroundImageUrl,
  children,
}: HeroProps) {
  return (
    <>
      <div
        className={classes.wrapper}
        style={{backgroundImage: `url(${backgroundImageUrl})`}}>
        <Overlay color="#000" opacity={0.85} zIndex={1} />

        <div className={classes.inner}>
          <Title className={classes.title}>{title}</Title>

          {description && (
            <Container>
              <Text size="lg" className={classes.description}>
                {description}
              </Text>
            </Container>
          )}

          {children}
        </div>
      </div>
    </>
  );
}
