import {useMutation} from "@apollo/client";
import {
  faExclamationTriangle,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import {
  Button,
  Center,
  Group,
  Space,
  Text,
  Textarea,
  TextInput,
  Tooltip,
} from "@mantine/core";
import {ContextModalProps, modals} from "@mantine/modals";
import {notifications} from "@mantine/notifications";
import {Formik, FormikErrors} from "formik";
import {isEmpty} from "lodash";
import React, {useState} from "react";

import config from "../../config";
import * as mutations from "../../graphql/mutations";
import type {InvitationInput} from "../../graphql/types";
import ErrorAlert from "../ErrorAlert";
import HelpLink from "../HelpLink";

/**
 * Invitation dialog
 */
export default function Invite({context, id, innerProps}: ContextModalProps) {
  const [lastError, setLastError] = useState<string | null>(null);
  const [invite, _inviteResult] = useMutation(mutations.INVITE);

  const inviterWatchCredits: number | null = null;
  const inviteeWatchCredits: number | null = null;

  return (
    <div>
      {inviterWatchCredits && (
        <h3>
          Invite a friend and get 20 more{" "}
          <HelpLink slug="watch-credits" title="watch credits" /> for free!
        </h3>
      )}
      {inviteeWatchCredits && (
        <Text size="md">
          Your friend will also get an extra 10 watch credits of their own.
        </Text>
      )}
      {(inviterWatchCredits || inviteeWatchCredits) && <Space h="xl" />}
      <Formik<InvitationInput>
        initialValues={{
          email: "",
          captchaResponse: "",
          message: "",
        }}
        validate={values => {
          const errors: FormikErrors<InvitationInput> = {};

          if (!values.email) {
            errors.email = "Email is required";
          }

          return errors;
        }}
        onSubmit={async (values, {setSubmitting, setErrors}) => {
          setLastError(null);

          try {
            const {
              data: {users: {invite: inviteResponse = undefined} = {}} = {},
            } = await invite({
              variables: {invitation: values},
            });

            // Form error response?
            if (inviteResponse.__typename == "FormError") {
              if (
                !inviteResponse.field ||
                inviteResponse.field === "captchaResponse"
              ) {
                // General error
                setLastError(inviteResponse.message);
              } else {
                // Field-specific error
                setErrors({[inviteResponse.field]: inviteResponse.message});
              }

              return;
            }

            notifications.show({
              title: "Invitation sent",
              message: (
                <Group>
                  <FontAwesomeIcon icon={faEnvelope} />
                  <div>
                    <Text size="md">
                      Your invitation was sent to {values.email}
                    </Text>
                    <Text size="xs">
                      We&apos;ll notify you when they sign up!
                    </Text>
                  </div>
                </Group>
              ),
              color: "green",
              position: "top-center",
              autoClose: false,
            });

            context.closeModal(id);
          } catch (e: any) {
            setLastError(e.message);
          } finally {
            setSubmitting(false);
          }
        }}>
        {({
          handleSubmit,
          handleChange,
          values,
          errors,
          dirty,
          isValidating,
          isSubmitting,
          submitCount,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <ErrorAlert message={lastError} />

            <TextInput
              name="email"
              label="Email address"
              placeholder="Your friend's email address"
              required
              error={submitCount > 0 && errors.email}
              value={values.email}
              onChange={handleChange}
            />

            <Space h="sm" />

            <Textarea
              name="message"
              label="Custom message to include in invitation (optional)"
              placeholder="You can write a custom message to your friend here..."
              error={submitCount > 0 && errors.message}
              value={values.message || ""}
              onChange={handleChange}
            />

            <Space h="xl" />

            <Center>
              <HCaptcha
                sitekey={config.hcaptcha.siteKey}
                onVerify={(token, _ekey) => {
                  setFieldValue("captchaResponse", token);
                }}
              />
            </Center>

            <Space h="xl" />

            <Center>
              <Button.Group>
                <Tooltip
                  withArrow
                  label={
                    isEmpty(errors)
                      ? "Send invitation"
                      : "Please correct errors"
                  }
                  color={isEmpty(errors) ? undefined : "red"}>
                  <Button
                    variant="primary"
                    onClick={() => handleSubmit()}
                    disabled={!dirty || isValidating || isSubmitting}
                    loading={isSubmitting}
                    leftSection={
                      !isSubmitting &&
                      !isEmpty(errors) &&
                      submitCount > 0 && (
                        <FontAwesomeIcon
                          icon={faExclamationTriangle}
                          beatFade
                        />
                      )
                    }>
                    Send invitation
                  </Button>
                </Tooltip>
                <Button
                  variant="default"
                  onClick={() => context.closeModal(id)}>
                  Cancel
                </Button>
              </Button.Group>
            </Center>
          </form>
        )}
      </Formik>
    </div>
  );
}

/**
 * Show the invitation dialog
 */
export function show() {
  modals.openContextModal({
    modal: "invite",
    title: "Invite a friend",
    size: "lg",
    innerProps: {},
  });
}
