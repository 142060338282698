/**
 * Menu context
 *
 * Displays context menu in portal
 */
import {noop} from "lodash";
import React, {useState, createContext} from "react";

import Portal from "../Portal";

interface Cursor {
  x: number;
  y: number;
}

interface UseMenu {
  content: React.ReactNode;
  cursor: Cursor | null;
  showMenu(content: React.ReactNode, cursor: Cursor): void;
  closeMenu(index?: number): void;
}

export function useMenu(): UseMenu {
  const [content, setContent] = useState<React.ReactNode>(null);
  const [cursor, setCursor] = useState<Cursor | null>(null);

  return {
    content,
    cursor,
    showMenu: (content: React.ReactNode, cursor: Cursor): void => {
      setContent(content);
      setCursor(cursor);
    },
    closeMenu: () => setContent(null),
  };
}

let MenuContext: React.Context<UseMenu>;
const {Provider} = (MenuContext = createContext<UseMenu>({
  content: null,
  cursor: null,
  showMenu: noop,
  closeMenu: noop,
}));

export interface MenuProviderProps {
  targetId: string;
  children: React.ReactNode;
}

const MenuProvider = ({targetId, children}: MenuProviderProps) => {
  const providerValue = useMenu();
  const {content, cursor} = providerValue;

  /*
  useEffect(() => {
    if (!content) {
      return;
    }

    const handler = event => {
      event.preventDefault();
    };

    document.addEventListener("contextmenu", handler);

    return () => document.removeEventListener("contextmenu", handler);
  }, [content]);
  */

  return (
    <Provider value={providerValue}>
      <Portal targetId={targetId}>
        {content && (
          <div
            style={
              cursor
                ? {
                    position: "absolute",
                    top: `${cursor.y}px`,
                    left: `${cursor.x}px`,
                  }
                : undefined
            }>
            {content}
          </div>
        )}
      </Portal>
      {children}
    </Provider>
  );
};

export {MenuContext, MenuProvider};
