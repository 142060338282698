import {Container, SimpleGrid, Text} from "@mantine/core";
import {
  IconTicket,
  IconCompass,
  IconSearch,
  IconBell,
  IconPencil,
  IconRadar,
} from "@tabler/icons-react";
import React from "react";

import Feature, {FeatureProps} from "./Feature";
import classes from "./FeatureContainer.module.scss";

export const FEATURES: Array<FeatureProps> = [
  {
    icon: IconSearch,
    title: "Search with ease",
    description:
      "How many diffent agency and business booking web sites do you have to navigate just to find what's available? " +
      "We make your search experience easy!",
  },
  {
    icon: IconCompass,
    title: "Discover what's nearby",
    description:
      "Feeling a little more spontaneous? Discover nearby places you can book a camp or boondock.",
  },
  {
    icon: IconPencil,
    title: "Plan trips better",
    description:
      "Have specific dates and camp site requirements in mind? We'll help you find an appropriate camp site" +
      "for your specific needs.",
  },
  {
    icon: IconTicket,
    title: "Secure your reservation",
    description:
      "We help you easily zero in on an available camp site in the area you're staying.",
  },
  {
    icon: IconRadar,
    title: "Scan for availability",
    description:
      "Want to see what's available right now? We can instantly check.",
  },
  {
    icon: IconBell,
    title: "Alerts",
    description:
      "Find it hard to reserve a popular camp site? We'll watch it and notify you when another spot opens up or someone cancels!",
  },
];

export interface FeatureContainerProps {
  features?: Array<FeatureProps>;
}

/**
 * Container of all the app's features
 * Used for marketing
 */
export default function FeatureContainer({
  features = FEATURES,
}: FeatureContainerProps) {
  return (
    <Container className={classes.wrapper}>
      <h3 className={classes.title}>
        Is it frustrating trying to find and reserve camp sites?
      </h3>

      <Container size={560} p={0}>
        <Text size="sm" className={classes.description}>
          Camp Observer will help you find and reserve the camp site you want on
          the dates you want at the best type of camp site that fits your needs.
        </Text>
      </Container>

      <SimpleGrid
        mt={60}
        cols={{base: 1, sm: 2, md: 3}}
        spacing={{base: "xl", md: 50}}
        verticalSpacing={{base: "xl", md: 50}}>
        {features.map((feature, index) => (
          <Feature {...feature} key={index} />
        ))}
      </SimpleGrid>
    </Container>
  );
}
