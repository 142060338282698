import {Avatar as MantineAvatar, MantineSize} from "@mantine/core";

import type {User} from "../graphql/types";

export interface AvatarProps {
  user: User;
  size?: MantineSize;
  editable?: boolean;
}

export default function Avatar({
  user,
  size = "sm",
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  editable = false,
}: AvatarProps) {
  return <MantineAvatar size={size} src={user.avatarImageUrl} />;
}
