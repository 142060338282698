// Name of the cookie the session key is stored in
export const TOKEN_COOKIE_NAME = "cotoken";

// If a slot hasn't been checked in this many days, it's considered stale
export const SLOT_STALE_THRESHOLD_DAYS = 1;

export const APP_NAME = "Camp Observer";
export const APP_DESCRIPTION =
  "Camp Observer makes it easy to find and reserve camp sites";

export const NOT_FOUND_IMAGE_URL = "/images/image_not_found.jpg";

export const URL_DATE_FORMAT = "YYYY-MM-DD";

// TODO: Remove this requirement after beta period
export const SIGNUP_REASON_REQUIRED = false;

// Default zoom level when searching for a location on the map or on the home page
export const DEFAULT_LOCATION_ZOOM = 15;

export const DEFAULT_SLOTS_DAYS = 14;

export const BLM_ORG_ID = 1;
export const USFS_ORG_ID = 22;
