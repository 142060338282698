import {motion} from "framer-motion";
import React, {useEffect, useState} from "react";
import NoSSR from "react-no-ssr";

interface TyperProps {
  text: string; // Message to display
}

/**
 * Displays message using a typing animation
 */
export default function Typer({text}: TyperProps) {
  const [previousText, setPreviousText] = useState<string>("");
  const [visibleText, setVisibleText] = useState<string>(text);
  const [transitioning, setTransitioning] = useState<boolean>(false);

  // TODO: Configurable delay
  useEffect(() => {
    setPreviousText(visibleText);
    setTransitioning(true);
    setTimeout(() => {
      setTransitioning(false);
      setVisibleText(text);
    }, 150);
  }, [text]);

  return (
    <NoSSR>
      {transitioning ? (
        <span style={{visibility: "hidden"}}>{previousText}</span>
      ) : (
        visibleText.split("").map((ch, i) => (
          <motion.span
            key={i}
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            transition={{
              duration: 0.25,
              delay: i / 10,
            }}>
            {ch}
          </motion.span>
        ))
      )}
    </NoSSR>
  );
}
