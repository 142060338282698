import {useQuery} from "@apollo/client";
import {Progress, Text, Tooltip} from "@mantine/core";
import pluralize from "pluralize";

import * as queries from "../../graphql/queries";
import {useAuth} from "../contexts/AuthContext";
import HelpLink from "../HelpLink";
import classes from "./WatchCredits.module.scss";

export interface WatchCreditsProps {
  plannedUsedCredits?: number; // Additional credits the user plans on using based on the changes they're about to make
  adding?: boolean;
}

/**
 * Watch credit usage meter
 */
export default function WatchCredits({
  plannedUsedCredits = 0,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  adding = false,
}: WatchCreditsProps) {
  const {data: {watches: {watchList: watches = []} = {}} = {}} = useQuery(
    queries.GET_WATCH_LIST,
    {
      variables: {
        status: "ACTIVE",
      },
    }
  );

  const {authentication} = useAuth();
  if (!authentication.user) {
    return null;
  }

  if (!watches) {
    return null;
  }

  // TODO: Get these with a direct call to queries.GET_CURRENT_USER since this may only be refreshed after login or full page reload
  const {usedWatchCredits, maxWatchCredits} = authentication.user;

  // Credits the user currently has remaining
  const remainingCredits = maxWatchCredits - usedWatchCredits;
  const remainingPercent = remainingCredits / (maxWatchCredits / 100);

  // Credits the user has remaining if they apply `plannedUsedCredits`
  const plannedRemainingCredits = remainingCredits - plannedUsedCredits;
  const plannedRemainingPercent = plannedUsedCredits / (maxWatchCredits / 100);

  // Label: Credits the user currently has remaining
  const remainingLabel =
    plannedUsedCredits > 0
      ? `You will have ${plannedRemainingCredits.toLocaleString()} watch ${pluralize("credit", plannedRemainingCredits)} remaining`
      : `You have ${remainingCredits.toLocaleString()} watch ${pluralize("credit", remainingCredits)} remaining`;

  // Label: Credits the user has remaining if they apply `planeedUsedCredits`
  const plannedUsedLabel =
    plannedUsedCredits > 0
      ? `Your changes will use ${plannedUsedCredits.toLocaleString()} additional watch ${pluralize("credit", plannedUsedCredits)}`
      : remainingLabel;

  // Label: User exceeded their credit limits
  const overageLabel =
    plannedRemainingCredits >= 0
      ? null
      : `Because you've exceeded your limits, you need to either add ${-plannedRemainingCredits.toLocaleString()} more ${pluralize("credit", -plannedRemainingCredits)} or remove ${-plannedRemainingCredits.toLocaleString()} campgrounds from your existing watches`;

  // Color of the progress bar based on how many credits remaing
  let progressColor: string;
  if (remainingPercent < 25) {
    progressColor = "red";
  } else if (remainingPercent < 50) {
    progressColor = "yellow";
  } else {
    progressColor = "green";
  }

  return (
    <div className={classes.watchCredits}>
      {/*!adding && (
        <div className="float-right">
          <Button
            size="xs"
            leftSection={<FontAwesomeIcon icon={faPlus} />}
            variant={remainingPercent >= 34 ? "light" : "primary"}
            onClick={event => {
              event.preventDefault();
              showAddWatchCredits();
            }}>
            Add credits
          </Button>
        </div>
      )*/}
      <div className={classes.remaining}>
        {remainingCredits > 0 ? (
          <Text size="md" className={classes.alreadyUsed}>
            You have {remainingCredits.toLocaleString()} of{" "}
            {maxWatchCredits.toLocaleString()}{" "}
            <HelpLink slug="watch-credits" title="watch credits" /> remaining
          </Text>
        ) : (
          <Text size="md" className={classes.alreadyUsed}>
            You have used all {maxWatchCredits.toLocaleString()} of your watch{" "}
            {pluralize("credit", maxWatchCredits)}
            {remainingCredits < 0 && (
              <span>
                {" "}
                and you have an additional deficit of{" "}
                {-remainingCredits.toLocaleString()}{" "}
                {pluralize("credit", -remainingCredits)}
              </span>
            )}
            !
          </Text>
        )}
        {plannedUsedCredits > 0 && (
          <Text size="sm">
            Your changes will use {plannedUsedCredits.toLocaleString()}{" "}
            additional {pluralize("credit", plannedUsedCredits)}
          </Text>
        )}
        {overageLabel && (
          <Text size="sm" className={classes.overage}>
            {overageLabel}
          </Text>
        )}
      </div>
      <Progress.Root size="lg">
        <Tooltip label={remainingLabel}>
          <Progress.Section
            value={remainingPercent - plannedRemainingPercent}
            color={progressColor}
          />
        </Tooltip>
        {remainingCredits > 0 && (
          <Tooltip label={plannedUsedLabel}>
            <Progress.Section
              value={plannedRemainingPercent}
              color={progressColor}
              striped
            />
          </Tooltip>
        )}
      </Progress.Root>
    </div>
  );
}
