import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Alert} from "@mantine/core";
import React from "react";

export interface ErrorAlertProps {
  message?: string | null; // Error message
}

/**
 * Error alert message
 *
 * Conditionally displayed depending on if message is present
 * Usually used at the top of forms
 */
export default function ErrorAlert({message}: ErrorAlertProps) {
  if (!message) {
    return null;
  }

  return (
    <Alert
      variant="filled"
      color="red"
      icon={<FontAwesomeIcon icon={faExclamationTriangle} />}>
      {message}
    </Alert>
  );
}
