import {Text, ThemeIcon} from "@mantine/core";
import React from "react";

export interface FeatureProps {
  icon: React.FC<any>; // Icon to display
  title: React.ReactNode; // Feature name
  description: React.ReactNode; // Feature description
}

/**
 * Feature box
 *
 * Contains marketing copy about a given feature
 */
export default function Feature({
  icon: Icon,
  title,
  description,
}: FeatureProps) {
  return (
    <div>
      <ThemeIcon variant="light" size={40} radius={40}>
        <Icon size={18} stroke={1.5} />
      </ThemeIcon>
      <Text mt="sm" mb={7}>
        {title}
      </Text>
      <Text size="sm" c="dimmed" lh={1.6}>
        {description}
      </Text>
    </div>
  );
}
