import {gql} from "@apollo/client";

import * as fragments from "./fragments";

// https://www.apollographql.com/docs/react/get-started

const filterVars = `
    $xmin: Float
    $ymin: Float
    $xmax: Float
    $ymax: Float
    $beginDate: Date
    $endDate: Date
    $nights: Int
    $people: Int
    $strict: Boolean = false
    $group: Boolean = false
    $firesAllowed: Boolean = false
    $petsAllowed: Boolean = false
    $firePit: Boolean = false
    $picnicTable: Boolean = false
    $waterHookup: Boolean = false
    $sewerHookup: Boolean = false
    $minAmps: Int
    $maxAmps: Int
    $drivewayLengthFeet: Int
    $vehicles: Int
    $accessible: Boolean = false
    $excludeGroup: Boolean = false
    $excludeFCFS: Boolean = false
    $excludeUnreleased: Boolean = false
    $excludeMandatoryEquipment: Boolean = false
`;

const filterInput = `
  {
    bounds: {xmin: $xmin, ymin: $ymin, xmax: $xmax, ymax: $ymax}
    dates: {min: $beginDate, max: $endDate}
    nights: $nights
    people: {max: $people}
    isStrict: $strict
    onlyGroup: $group
    onlyFiresAllowed: $firesAllowed
    onlyPetsAllowed: $petsAllowed
    onlyAccessible: $accessible
    onlyFirePit: $firePit
    onlyPicnicTable: $picnicTable
    onlyWaterHookup: $waterHookup
    onlySewerHookup: $sewerHookup
    electricityHookupAmps: {min: $minAmps, max: $maxAmps}
    drivewayLengthFeet: $drivewayLengthFeet
    vehicles: $vehicles
    excludeGroup: $excludeGroup
    excludeFCFS: $excludeFCFS
    excludeUnreleased: $excludeUnreleased
    excludeMandatoryEquipment: $excludeMandatoryEquipment
  }
`;

export const MAP_SEARCH = gql`
  ${fragments.PAGINATION_FIELDS}

  query MapSearch(
    $filter: FilterInput
    $zoom: Float
    $onlyMatches: Boolean = false
    $page: Int = 1
    $pageSize: Int = 100
    $sortField: String
    $sortDirection: SortDirection
    $cluster: Boolean = false
  ) {
    search {
      mapSearch(
        input: {
          filter: $filter
          zoom: $zoom
          onlyMatches: $onlyMatches
          pagination: {page: $page, pageSize: $pageSize}
          sort: {field: $sortField, direction: $sortDirection}
          cluster: $cluster
        }
      ) {
        results {
          ... on SearchResult {
            type
            id
            slug
            name
            location {
              latitude
              longitude
            }
            geometryUrl
            organizationId
            isMatch
            isShared
            isWatched
            updatedAt
          }
          ... on MapCluster {
            name
            count
            location {
              latitude
              longitude
            }
            isMatch
            isShared
            isWatched
            expansionZoom
          }
        }
        pagination {
          ...PaginationFields
        }
      }
    }
  }
`;

// TODO: Convert to textSearch()
export const PICKER_SEARCH = gql`
  query PickerSearch($term: String, $filter: FilterInput) {
    search {
      mapSearch(
        input: {
          term: $term
          filter: $filter
          onlyMatches: false
          pagination: {page: 1, pageSize: 10}
          cluster: false
        }
      ) {
        results {
          ... on SearchResult {
            type
            subtype
            id
            slug
            name
            context
            location {
              latitude
              longitude
            }
            result {
              ... on Facility {
                park {
                  name
                }
              }
              ... on Unit {
                facility {
                  name
                }
              }
            }
            isMatch
          }
        }
      }
    }
  }
`;

export const TEXT_SEARCH = gql`
  query TextSearch(
    $term: String!
    $filter: FilterInput
    $near: LocationInput
    $correlationKey: String
    $page: Int
    $pageSize: Int
  ) {
    search {
      textSearch(
        input: {
          term: $term
          filter: $filter
          near: $near
          correlationKey: $correlationKey
          pagination: {page: $page, pageSize: $pageSize}
        }
      ) {
        results {
          id
          slug
          type
          subtype
          name
          context
        }
        correlationKey
      }
    }
  }
`;

export const TEXT_SEARCH_RESULT_LOCATION = gql`
  query TextSearchResultLocation(
    $mapableType: String!
    $mapableId: String!
    $correlationKey: String
  ) {
    search {
      textSearchResultLocation(
        mapableType: $mapableType
        mapableId: $mapableId
        correlationKey: $correlationKey
      ) {
        latitude
        longitude
      }
    }
  }
`;

export const GET_ORGANIZATION_DETAILS = gql`
  ${fragments.ORGANIZATION_FIELDS}

  query GetOrganizationDetails($id: Int!) {
    entities {
      organization(organizationId: $id) {
        ...OrganizationFields
      }
    }
  }
`;

export const GET_PARK_DETAILS = gql`
  ${fragments.PARK_FIELDS}

  query GetParkDetails($id: Int, $slug: String) {
    entities {
      park(park: {id: $id, slug: $slug}) {
        ...ParkFields
      }
    }
  }
`;

export const GET_FACILITY_DETAILS = gql`
  ${fragments.FACILITY_FIELDS}

  query GetFacilityDetails($id: Int, $slug: String) {
    entities {
      facility(facility: {id: $id, slug: $slug}) {
        ...FacilityFields
        park {
          id
          slug
          name
          description
          descriptionHtml
        }
      }
    }
  }
`;

export const GET_FACILITY_POPOVER = gql`
  ${fragments.FACILITY_COMMON_FIELDS}
  ${fragments.UNIT_FIELDS}
  ${fragments.SLOT_FIELDS}

  query GetFacilityPopover(
    $id: Int
    $slug: String
    $filter: FilterInput
    $onlyMatches: Boolean = true
    $page: Int = 1
    $pageSize: Int
    $sortField: String
    $sortDirection: SortDirection
  ) {
    entities {
      facility(facility: {id: $id, slug: $slug}) {
        ...FacilityCommonFields
        park {
          id
          slug
          name
          description
          descriptionHtml
        }
        units(
          input: {
            filter: $filter
            onlyMatches: $onlyMatches
            pagination: {page: $page, pageSize: $pageSize}
            sort: {field: $sortField, direction: $sortDirection}
          }
        ) {
          ...UnitFields
          slots {
            ...SlotFields
          }
        }
      }
    }
  }
`;

export const GET_FACILITY_DETAILS_PAGE = gql`
  ${fragments.FACILITY_COMMON_FIELDS}
  ${fragments.UNIT_FIELDS}
  ${fragments.SLOT_FIELDS}

  query GetFacilityDetailsPage(
    $id: Int
    $slug: String
    $filter: FilterInput
    $onlyMatches: Boolean = true
    $page: Int = 1
    $pageSize: Int
    $sortField: String
    $sortDirection: SortDirection
  ) {
    entities {
      facility(facility: {id: $id, slug: $slug}) {
        ...FacilityCommonFields
        park {
          id
          slug
          name
          description
          descriptionHtml
        }
        nearbyFacilities {
          facility {
            ...FacilityCommonFields
          }
          distanceMiles
        }
        units(
          input: {
            filter: $filter
            onlyMatches: $onlyMatches
            pagination: {page: $page, pageSize: $pageSize}
            sort: {field: $sortField, direction: $sortDirection}
          }
        ) {
          ...UnitFields
          slots {
            ...SlotFields
          }
        }
      }
    }
  }
`;

export const GET_UNIT_DETAILS = gql`
  ${fragments.UNIT_FIELDS}

  query GetUnitDetails($id: Int, $slug: String) {
    entities {
      unit(unit: {id: $id, slug: $slug}) {
        ...UnitFields
        facility {
          id
          slug
          name
          description
          descriptionHtml
          park {
            id
            slug
            name
            description
            descriptionHtml
          }
        }
      }
    }
  }
`;

export const GET_UNIT_DETAILS_WITH_SLOTS = gql`
  ${fragments.UNIT_FIELDS}
  ${fragments.SLOT_FIELDS}

  query GetUnitDetailsWithSlots(
    $id: Int
    $slug: String
    $beginDate: Date!
    $endDate: Date!
  ) {
    entities {
      unit(unit: {id: $id, slug: $slug}) {
        ...UnitFields
        slots(input: {beginDate: $beginDate, endDate: $endDate}) {
          ...SlotFields
        }
        facility {
          id
          slug
          name
          description
          descriptionHtml
          park {
            id
            slug
            name
            description
            descriptionHtml
          }
        }
      }
    }
  }
`;

export const GET_CURRENT_USER = gql`
  ${fragments.AUTHENTICATED_USER_FIELDS}

  query GetCurrentUser {
    users {
      currentUser {
        ...AuthenticatedUserFields
      }
    }
  }
`;

export const GET_MAX_DATE = gql`
  query GetMaxDate {
    search {
      maxDate
    }
  }
`;

export const GET_LOCATION = gql`
  query GetLocation {
    users {
      location {
        latitude
        longitude
      }
    }
  }
`;

export const GET_FEATURE_FLAG = gql`
  query GetFeatureFlag($flag: String!) {
    features {
      isEnabled(flag: $flag)
    }
  }
`;

export const GET_CHECKOUT_CONFIG = gql`
  query GetCheckoutConfig {
    subscriptions {
      checkoutConfig {
        publishableKey
        plans {
          id
          name
          description
          amount
          currency
          interval
        }
      }
    }
  }
`;

export const GET_WATCH_LIST = gql`
  ${fragments.WATCH}

  query GetWatchList($status: WatchStatus) {
    watches {
      watchList(status: $status) {
        ...WatchFields
      }
    }
  }
`;

export const GET_WATCH = gql`
  ${fragments.WATCH}

  query GetWatch($id: Int!) {
    watches {
      watch(watchId: $id) {
        ...WatchFields
      }
    }
  }
`;

export const GET_WATCH_FOUND_UNITS = gql`
  ${fragments.UNIT_FIELDS}
  ${fragments.SLOT_FIELDS}
  ${fragments.FACILITY_WITHOUT_UNITS_FIELDS}

  query GetWatchFoundUnits($id: Int!, $beginDate: Date!, $endDate: Date!) {
    watches {
      watch(watchId: $id) {
        id
        foundUnits {
          ...UnitFields
          facility {
            ...FacilityWithoutUnitsFields
          }
          slots(input: {beginDate: $beginDate, endDate: $endDate}) {
            ...SlotFields
          }
        }
      }
    }
  }
`;

export const GET_RECENTLY_CHECKED_FACILITIES = gql`
  query GetRecentlyCheckedFacilities {
    entities {
      recentlyCheckedFacilities {
        id
        slug
        name
        park {
          id
          slug
          name
        }
        lastCheckedAt
      }
    }
  }
`;

export const CALCULATE_USED_CREDITS = gql`
  query CalculateUsedCredits(
    $parkIds: [Int!]!
    $facilityIds: [Int!]!
    $unitIds: [Int!]!
  ) {
    subscriptions {
      calculateUsedCredits(
        parkIds: $parkIds
        facilityIds: $facilityIds
        unitIds: $unitIds
      )
    }
  }
`;

export const EMAIL_TAKEN = gql`
  query EmailTaken($email: String!, $captchaResponse: String!) {
    users {
      emailTaken(input: {email: $email, captchaResponse: $captchaResponse}) {
        ... on EmailTakenSuccessResult {
          email
          taken
        }
        ... on FormError {
          field
          message
        }
      }
    }
  }
`;

export const GET_CONTENT_SUMMARY = gql`
  ${fragments.CONTENT_FIELDS}

  query GetContentSummary($slug: String!, $type: String!) {
    cms {
      content(slug: $slug, contentType: $type) {
        ...ContentFields
      }
    }
  }
`;

export const GET_CONTENT_DETAILS = gql`
  ${fragments.CONTENT_FIELDS}
  ${fragments.CONTENT_ATTACHMENT_FIELDS}

  query GetContentDetails($slug: String!, $type: String!) {
    cms {
      content(slug: $slug, contentType: $type) {
        ...ContentFields
        attachments {
          ...ContentAttachmentFields
        }
      }
    }
  }
`;

export const CONTENT_SLUG_AVAILABLE = gql`
  query ContentSlugAvailable($slug: String!, $type: String!) {
    cms {
      slugAvailable(slug: $slug, contentType: $type)
    }
  }
`;

export const GET_INVITATION_DEFAULTS = gql`
  query GetInvitationDefaults($token: String!) {
    users {
      invitationDefaults(token: $token) {
        email
      }
    }
  }
`;
