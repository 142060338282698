import {Loader as MantineLoader, MantineSize} from "@mantine/core";
import classNames from "classnames";

import classes from "./Loader.module.scss";

export interface LoaderProps {
  size?: MantineSize; // Size of loader
  centered?: boolean; // Center loader on page?
  className?: string; // CSS class name
}

/**
 * Animated "loading" image
 */
export default function Loader({
  size = "sm",
  centered = false,
  className,
}: LoaderProps) {
  const icon = <MantineLoader size={size} />;

  if (centered) {
    return (
      <div className={classNames(classes.loaderContainer, className)}>
        {icon}
      </div>
    );
  }

  return icon;
}
